import React from 'react'
import { get_platform_bg_color } from '../../script/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const FloatingBar = ({ data }) => {
    const queryParams = new URLSearchParams(window.location.search);

    const closeAdminToggle = () => {
        document.getElementsByClassName('adminSwitchOverlay')[0].style.display = 'none'
    }
    const viewAsAdmin = () => {
        window.location.href = data?.btn?.url
    }

    return (
        <div>
            {data && <div className="adminSwitchOverlay" style={{background: get_platform_bg_color()}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {data?.title && <div>{data?.title}</div>}
                    {data?.btn && <div style={{display:"flex",padding:"0 20px", justifyContent:"space-around"}}>
                        <div className="toggleText" onClick={viewAsAdmin}>{data?.btn?.text}</div>
                    </div>}
                    <div><FontAwesomeIcon icon={faXmark} onClick={closeAdminToggle} style={{cursor:"pointer"}}/></div>
                </div>
            </div>}
        </div>
        
    )
}

export default FloatingBar