import React, { useEffect, useLayoutEffect, useState } from 'react'
import StoreToken from '../../components/StoreToken';
import { useStateContext } from '../../context/StateContext';
import { catchError, get_platform_action_color, get_platform_bg_color, getAPIUrl, getAuthToken, getWhatsappShareUrl, globalLoader } from '../../script/util';
import axios from 'axios';
import Toast from "../../components/Toast/Toast.js";
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';
import './ShareUrls.css'
import { Link } from 'react-router-dom';
import homeIcon from '../../images/home.png'
import linkIcon from '../../images/link.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate, faCircleInfo, faLink, faLinkSlash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Popup from '../../components/Popup/Popup';

const ShareUrls = () => {
  const { isLoggedin } = useStateContext();
  const { isOpen, setisOpen } = useStateContext();
  const { storeToLocal } = StoreToken();
  const [loading, setLoading] = useState(false)
  const [currentDimensions, setCurrentDimensions] = useState(window?.innerWidth)
  const [shareDetailsArray, setShareDetailsArray] = useState({});
  const [book_Pk, setBook_Pk] = useState()
  const [copied, setCopied] = useState({})
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState()
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [isRegenerate, setIsRegenerate] = useState(false)
  const [popupData, setPopupData] = useState(null)
  const [shareData, setShareData] = useState()
  const [addNicknamePopup, setAddNicknamePopup] = useState(false)
  const [nickname, setNickname] = useState("")
  const [nicknamePasscode, setNicknamePasscode] = useState(null);

  const [isSharedTagInfoPopUpOpen, setIsSharedTagInfoPopUpOpen] = useState(false);
  const [selectedInfoTag, setSelectedInfoTag] = useState(null);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const Everything = "Everything";
  const Selective = "Selective";
  const FaceOnly = "Face only";
  const GuestUpload = "Guest Upload";
  
  useLayoutEffect(() => {
    storeToLocal();
  }, []);


  const handleCopy = (id, url) => {
    navigator.clipboard.writeText(url.share_url)
      .then(() => {
        setCopied((prev) => ({ ...prev, [id]: true }));
        setTimeout(() => {
          setCopied((prev) => ({ ...prev, [id]: false }));
        }, 2000);
        setShowToast(true)
        setToastMessage('Copied!')
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const profileName = () => {
    const nameInitial = JSON.parse(localStorage.getItem('auth_dict')).eventGraphiaInitials
    return nameInitial
  }

  const handleOrientationChange = () => {
    setCurrentDimensions(window.innerWidth)
  };

  useEffect(() => {
      window.addEventListener('resize', handleOrientationChange);
  
      return () => {
        window.removeEventListener('resize', handleOrientationChange);
      };
  }, []);

  const getShareDetail = async (booking_pk) => {
    setLoading(true);
    setBook_Pk(booking_pk)
    if (shareDetailsArray.hasOwnProperty(booking_pk)) {
        return;
    }
    if (booking_pk !== undefined) {
        try {
            const res = await axios.get(getAPIUrl(`/api/share/url/${booking_pk}/`), {
                headers: {
                    Authorization: getAuthToken(),
                },
            });

            const sharedCall = res.data;
            if (sharedCall.success) {
                const updatedShareDetailsArray = { ...shareDetailsArray };
                const x = sharedCall.shared_data;
                updatedShareDetailsArray[booking_pk] = {
                    shared_array: x,
                }
                setShareData(sharedCall)
                setShareDetailsArray(updatedShareDetailsArray);
            }
        } catch (error) {
            catchError(error);
        }
    }
  };

  const regenerateData = (pass, bookingPk) =>{
    setPopupData({ pass, bookingPk });
    setConfirmPopup(true);
  }

  const closeNicknamePopup = () => {
    setAddNicknamePopup(false);
    setNickname("");
    setNicknamePasscode(null);
  }

  const addNickname = async (booking_pk) => {

    try {

      const response = await fetch(getAPIUrl(`/api/share/url/nickname/${nicknamePasscode}/`), {
          method: 'PUT',
          headers: {
              Authorization: getAuthToken(),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ nickname: nickname, booking_pk: booking_pk }),
      });

      const res = await response.json();

      if (res.success) {
          setLoading(false)
          setShareDetailsArray((prev) => {
            return {
              ...prev,
              [book_Pk]: {
                ...prev[book_Pk],
                shared_array: prev[book_Pk].shared_array.map((item) =>
                  item.share_passcode === nicknamePasscode
                    ? {
                        ...item,
                        nickname: nickname
                      }
                    : item
                ),
              },
            };
          });
      } else {
          setLoading(false)
      }
  } catch (error) {
      setLoading(false)
      console.error('error while adding nickname:', error);

  }finally{
    setNickname("");
    setNicknamePasscode(null);
    setAddNicknamePopup(false);
  }
}

const clickEditNameIcon = (f, e) => {
  f.stopPropagation();
  f.preventDefault();
  setNickname(e.nickname);
  setAddNicknamePopup(true); 
  setNicknamePasscode(e.share_passcode);
}

const clickAddNameText = (f, e) => {
  f.stopPropagation();
  f.preventDefault();
  setAddNicknamePopup(true); 
  setNicknamePasscode(e.share_passcode);
}

  const regenerate = async () => {
    const { pass, bookingPk } = popupData;
    setConfirmPopup(false);
      try {
          const sharedData = {
              action_flag: 'regenerate',
              share_passcode: pass,
          };

          const response = await fetch(getAPIUrl(`/api/share/url/${bookingPk}/`), {
              method: 'PUT',
              headers: {
                  Authorization: getAuthToken(),
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(sharedData),
          });

          const res = await response.json();

          if (res.success) {
              setLoading(false)
              setShareDetailsArray((prev) => {
                return {
                  ...prev,
                  [bookingPk]: {
                    ...prev[bookingPk],
                    shared_array: prev[bookingPk].shared_array.map((item) =>
                      item.share_passcode === pass
                        ? {
                            ...item,
                            share_passcode: res.share_passcode,
                            share_url: res.share_url,
                            share_msg: res.share_msg,
                            hidden_base_view_url: res.hidden_base_view_url
                          }
                        : item
                    ),
                  },
                };
              });
          } else {
              setLoading(false)
          }
      } catch (error) {
          setLoading(false)
          console.error('API request failed:', error);

      }
  };

  const clickInfo = (f, e) => {
    f.stopPropagation(); 
    f.preventDefault(); 
    setSelectedInfoTag(e.share_tag); 
    setIsSharedTagInfoPopUpOpen(true)
  }

  const disableShareURL = (pass, is_disabled, bookingPk) => {
    var passcode = pass;
    var shared_data = {
        action_flag: 'deactivate',
        share_passcode: passcode,
        is_disabled: !is_disabled,
    };

    const config = {
        method: "PUT",
        url: getAPIUrl(`/api/share/url/${bookingPk}/`),
        headers: {
            Authorization: getAuthToken(),
        },
        data: shared_data,
        dataType: "json",
        contentType: "application/json; charset=utf-8",
    };
    axios(config)
        .then((response) => {
            setShareDetailsArray((prev) => {
              return {
                ...prev,
                [bookingPk]: {
                  ...prev[bookingPk],
                  shared_array: prev[bookingPk].shared_array.map((item) =>
                    item.share_passcode === pass
                      ? {
                          ...item,
                          is_disabled: response.data.is_disabled
                        }
                      : item
                  ),
                },
              };
            });
        })
        .catch((error) => {
            catchError(error)
        });
  };

  useEffect(()=>{
    getShareDetail(book_Pk)
  },[book_Pk])

  useEffect(()=>{
    setBook_Pk(id)
  },[])
  
  return (
    <>{(isLoggedin && shareDetailsArray[book_Pk]) ? <div>
      <Navbar location={"/sharedUrlList"} title={``}/>
      <div style={{backgroundColor: currentDimensions > 1200 && get_platform_bg_color()}} className= {`${(currentDimensions > 1200 ? 'topSwitchTabs' : 'bottomSwitchTabs')}`}>
          {shareData?.home_url && <p className={currentDimensions<=1200 ? "tabsPara" : ''} style={{position: currentDimensions > 1200 && "absolute", left:"60px", cursor:"pointer", paddingTop:"10px"}}><Link style={{display: 'flex', alignItems: 'center', flexDirection: currentDimensions<600 && 'column'}} to={shareData?.home_url?.url}>
              <img className={currentDimensions>1200 ? "invert-image" : ""} src={homeIcon} style={{marginRight:currentDimensions>600 && "5px", maxWidth:"25px"}}/>
              {currentDimensions<=1200 && <span>Home</span>}
            </Link></p>}
          {<p className="tabsPara" style={{borderBottom: (currentDimensions > 1200 ? '3px solid white' : '3px solid black')}} >
              <img className={currentDimensions>1200 ? "invert-image" : ""} src={linkIcon} style={{maxWidth:"25px"}} />
              <span>Shared Links</span>
          </p>}
          {currentDimensions<=1200 && <div className="tabsPara" onClick={() => setisOpen(true)}>
              <p className='profileDiv' style={{ background: get_platform_bg_color(), border: `1px solid ${get_platform_bg_color()}`, marginRight : currentDimensions>600 && "5px" }} >
              {profileName()}</p>
              <span>Account</span>
          </div>}
      </div>
      <div
        style={{
          paddingTop: currentDimensions > 1200 ? "200px" : "80px",
          display: "flex",
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: 'center'
        }}
      >
        <div className="share-details-container">
          {shareDetailsArray[book_Pk].shared_array.map((e, index) => (
            <div key={index} className="share-details-item">
              <div className="share-url" style={{pointerEvents: e.is_disabled && 'none', color: e.is_disabled && 'grey', textDecoration:e.is_disabled && 'line-through'}}>
                <Link style={{marginRight:currentDimensions<600 ? "50px" : "30px", lineHeight:"1.2", textAlign:"left"}} target='_blank' to={e.share_url}>
                {!e.is_disabled && !e.nickname && 
                  <i className="nickname-btn" onClick={(f) => clickAddNameText(f, e)} style={{cursor: "pointer", maxWidth: "fit-content", marginBottom: currentDimensions<600 && '20px', color: "#006BFF", backgroundColor: "transparent", fontSize: currentDimensions<600 ? '15px': "16px", padding: "0"}}>
                      Add a title <FontAwesomeIcon icon={faPenToSquare} style={{marginLeft: "5px", pointerEvents: e.is_disabled && 'none', color: e.is_disabled ? 'grey': "black", display: e.is_disabled && "none"}} size='sm' />
                  </i>
                }
                  <div style={{fontSize: currentDimensions<600 && '16px', marginTop: !e.nickname && "7px"}}>{e.nickname ? (<>{e.nickname}  <span>
                    <FontAwesomeIcon icon={faPenToSquare} style={{marginLeft: "5px", pointerEvents: e.is_disabled && 'none', color: e.is_disabled ? 'grey': "black", display: e.is_disabled && "none"}} size='sm' onClick={(f) => clickEditNameIcon(f, e)} />
                  </span></>  ) : e.hidden_base_view_url}</div>
                  {e.nickname && <div style={{fontSize: currentDimensions<600 ? '16px' : "17px", marginTop: "7px"}}>{e.hidden_base_view_url}</div>}
                  <div style={{fontSize: currentDimensions<600 ? '0.7rem' : "0.8rem", color:"grey", display: "flex", alignItems: "center", gap: "0.35rem"}}><span>{e.share_tag}</span><FontAwesomeIcon icon={faCircleInfo} onClick={(f) => clickInfo(f, e)} /></div>

                </Link>
                <button className="copy-btn" onClick={()=>handleCopy(index, e)} style={{pointerEvents: e.is_disabled && 'none', backgroundColor: e.is_disabled && 'grey'}}>
                  <FontAwesomeIcon icon={faCopy} style={{color: "#ffffff",}} size='lg'/>
                </button>
              </div>

              <div className="share-actions">
                <button className="disable-btn" onClick={()=>disableShareURL(e.share_passcode, e.is_disabled, book_Pk)} style={{backgroundColor: e.is_guest_upload ? 'grey' : (e.is_disabled && get_platform_action_color()), pointerEvents: e.is_guest_upload && 'none'}}>
                  {!e.is_disabled ? <FontAwesomeIcon icon={faLinkSlash} style={{color: "#ffffff"}} size='lg'/> : <FontAwesomeIcon icon={faLink} style={{color: "#ffffff",}} size='lg'/>}
                  <span style={{paddingLeft:"5px"}}>{e.is_disabled ? 'Enable' : 'Disable'}</span>
                </button>
                <button className="regenerate-btn" style={{background: (e.is_disabled || e.is_guest_upload) ? 'grey' : get_platform_bg_color(), pointerEvents: (e.is_disabled || e.is_guest_upload) && 'none'}} onClick={()=>regenerateData(e.share_passcode, book_Pk)}>
                  <FontAwesomeIcon icon={faArrowsRotate} style={{color: "#ffffff"}} size='lg'/>
                  <span style={{paddingLeft:"5px"}}>Regenerate</span>
                </button>
                <Link target='_blank' to={getWhatsappShareUrl(e.share_msg)} style={{pointerEvents: e.is_disabled && 'none'}}>
                  <button className="share-btn" style={{backgroundColor: e.is_disabled && 'grey'}}>
                    <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#ffffff", paddingBottom: currentDimensions<600 && '2px'}} size='lg'/>
                    <span className={currentDimensions<600 ? 'noText' : ''} style={{paddingLeft:"5px"}}>Share</span>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      {<Popup
        isOpen={confirmPopup}
        onClose={() => {
            setConfirmPopup(false);
        }}
        >
          <div>
            <div style={{padding:"10px 0", fontWeight:"600", textAlign:"left"}}>The existing link will be deactivated and a new link will be generated.</div>
            <div>
              <button style={{border:"1px solid black", padding:"5px 8px", borderRadius:"5px", marginRight:"20px"}} onClick={()=>setConfirmPopup(false)}>Cancel</button>
              <button style={{color: 'white', background: get_platform_bg_color(), padding:"5px 8px", borderRadius:"5px", border: `1px solid ${get_platform_bg_color()}`}} onClick={()=>regenerate()}>
                  <FontAwesomeIcon icon={faArrowsRotate} style={{color: "#ffffff"}} size='sm'/>
                  <span style={{paddingLeft:"5px"}}>Regenerate</span>
              </button>
            </div>
          </div>
      </Popup>}

    {<Popup isOpen={isSharedTagInfoPopUpOpen} onClose={() => setIsSharedTagInfoPopUpOpen(false)} >
    <div className="info_container">
      <h1 className="info_heading">{selectedInfoTag}</h1>
      {selectedInfoTag === Everything && 
        <p className="info_description">All new uploaded data will be visible in this link</p>
      }
      {selectedInfoTag === Selective && 
        <p className="info_description">Only selected data will be visible through this link.</p>
      }
      {selectedInfoTag === FaceOnly && 
        <ul>
          <li className="info_description">Using this link, guests can click a selfie and only see their photos.</li>
          <li className="info_description info_description_secondary">They will not have access to photos in which they are not present.</li>
        </ul>
      }
      {selectedInfoTag === GuestUpload && 
        <p className="info_description">All the data uploaded by the guests will be visible in this link.</p>
      }
      <button className='info_ok_btn' onClick={() => setIsSharedTagInfoPopUpOpen(false)} style={{ backgroundColor: get_platform_action_color()}}>Okay</button>
    </div>

    </Popup>}
      {<Popup
        isOpen={addNicknamePopup}
        onClose={() => {
            closeNicknamePopup();
        }}
        >
          <div>
            <div style={{padding:"10px 0", fontWeight:"600", textAlign:"left"}}>Give your shared link a name for easy reference :</div>
            <input
              type="text"
              className="form-input"
              value={nickname}
              style={{
                width: "100%",
                background: "whitesmoke",
                marginBottom: "10px",
                padding: "7px 9px",
                border: "2px solid black",
                borderRadius: "5px"
              }}
              maxLength={20}
              onChange={(e) => setNickname(e.target.value.trimStart())}
            />
            <div>
              <button style={{color: 'white', background: get_platform_bg_color(), padding:"5px 8px", borderRadius:"5px", border: `1px solid ${get_platform_bg_color()}`, opacity: nickname.trim() == "" && 0.5, cursor: nickname.trim() == "" && "not-allowed"}} onClick={()=>addNickname(book_Pk)} disabled={nickname.trim() == ""} >
                  <FontAwesomeIcon icon={faPenToSquare}  style={{color: "#ffffff",}} size='sm'/>
                  <span style={{paddingLeft:"5px"}}>Save name</span>
              </button>
            </div>
          </div>
      </Popup>}

      {showToast && <Toast message={toastMessage} duration={3000} onClose={() => setShowToast(false)}/>}
      {
        isOpen && <Sidebar />
      }
    </div> : <div className="overlayLoader">{globalLoader()}</div>}</>
  )
}

export default ShareUrls
