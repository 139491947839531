import React, { useEffect, useLayoutEffect, useState } from "react";
import { getAuthToken, get_platform_bg_color, globalLoader } from "../../script/util";
import { useStateContext } from '../../context/StateContext.js';
import axios from "axios";
import Navbar from '../../components/Navbar/Navbar.jsx'
import './Ratings.css'
import Toast from "../../components/Toast/Toast.js";
import StoreToken from "../../components/StoreToken";


const Ratings = () => {
  const { isLoggedin } = useStateContext();
  const [ratingsList, setRatingsList] = useState([]);
  const [bookingName, setBookingName] = useState(null);
  const { storeToLocal } = StoreToken();
  const [userRatings, setUserRatings] = useState({});
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState()
  const [currentDimensions, setCurrentDimensions] = useState(window?.innerWidth)

  useLayoutEffect(() => {
    storeToLocal();
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const bId = queryParams.get("id");

  const getUserRatings = async () => {
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STAG_URL}/api/booking/user-ratings/${bId}/`,
        { headers }
      );
      if (response.data.success) {
        setRatingsList(response.data.nps_list);
        setBookingName(response.data.booking_name);
      } else {
        setShowToast(true)
        setToastMessage(response.data.message)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrientationChange = () => {
    setCurrentDimensions(window.innerWidth)
  };


  const handleRatingChange = (id, score) => {
    setUserRatings((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        score,
      },
    }));
  };

  const handleFeedbackChange = (id, feedback) => {
    setUserRatings((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        suggestion: feedback,
      },
    }));
  };


  const handleEdit = (id) => {
    setUserRatings((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        disabled: !prev[id].disabled,
      },
    }));
  };

  let selectedElement = null;

  document.addEventListener('mouseover', (event) => {
    if (event.target.classList.contains('raterNumber') && event.target !== selectedElement) {
      const fdbkid = event.target.dataset.fdbkid;
      if(!userRatings[fdbkid]?.disabled){
        return;
      }
      const style = getComputedStyle(event.target);
      const borderColor = style.borderColor;

      event.target.style.boxShadow = `0 0 5px ${borderColor}`;
      event.target.style.backgroundColor = `${borderColor}`;
    }
  });

  document.addEventListener('mouseout', (event) => {
    if (event.target.classList.contains('raterNumber') && event.target !== selectedElement) {
      const fdbkid = event.target.dataset.fdbkid;
      if(!userRatings[fdbkid]?.disabled){
        return;
      }
      event.target.style.boxShadow = '';
      event.target.style.backgroundColor = '';
    }
  });

  document.addEventListener('click', (event) => {
    if (event.target.classList.contains('raterNumber')) {
      const fdbkid = event.target.dataset.fdbkid;
      if(!userRatings[fdbkid]?.disabled){
        return;
      }
      if (selectedElement && selectedElement.dataset.fdbkid === fdbkid && selectedElement !== event.target) {
        selectedElement.style.boxShadow = '';
        selectedElement.style.backgroundColor = '';
      }

      selectedElement = event.target;

      const style = getComputedStyle(event.target);
      const borderColor = style.borderColor;

      event.target.style.boxShadow = `0 0 5px ${borderColor}`;
      event.target.style.backgroundColor = `${borderColor}`;
    }
  });


  const handleSubmit = async (npsId) => {
    let ratingData = userRatings[npsId];
    if(!ratingData.score){
      setShowToast(true)
      setToastMessage("Please select a rating")
      return;
    }
    const authToken = getAuthToken();
    const headers = {
      Authorization: authToken,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STAG_URL}/api/booking/user-ratings/update/${npsId}/`,
        ratingData,
        { headers }
      );
      if (response.data.success) {
        let npsItem = response.data;
        let updatedNpsData = {
          score: npsItem.score,
          suggestion: npsItem.suggestion,
          disabled: npsItem.score || false,
          user_id: npsItem.user_id,
          user_name: npsItem.user_name || false,
          editable: false
        };

        const updatedRatingsList = [...ratingsList];
        const indexOfNpsItem = updatedRatingsList.findIndex(item => item.id === npsId);

        if (indexOfNpsItem !== -1) {
          updatedRatingsList[indexOfNpsItem] = {
            ...updatedRatingsList[indexOfNpsItem],
            ...updatedNpsData,
          };
          setRatingsList(updatedRatingsList);
        } else {
          console.warn("NPS item with ID", npsId, "not found in ratingsList");
        }
        handleRatingChange(npsItem.id, npsItem.score);
        handleFeedbackChange(npsItem.id, npsItem.suggestion)
        setShowToast(true)
        setToastMessage(npsItem.message)
      } else {
        setShowToast(true)
        setToastMessage(response.data.message)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserRatings();
  }, []);


  useEffect(() => {
    if (ratingsList) {
      const newUserRatings = {};
      ratingsList.forEach((item) => {
        newUserRatings[item.id] = {
          score: item.score || 0,
          suggestion: item.suggestion || "",
          disabled: !item.score || false,
          user_id: item.user_id,
          user_name: item.user_name,
          editable: false,
        };
      });
      setUserRatings(newUserRatings);
    }
  }, [ratingsList]);

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return (
    <>
    {(isLoggedin) ? (<div>
      <Navbar location={"/userRatings"} title={``} />
      <div style={{backgroundColor: currentDimensions > 1200 && get_platform_bg_color(), paddingTop: currentDimensions<=1200 && '10px'}} className= {`${(currentDimensions > 1200 ? 'topSwitchTabs' : 'bottomSwitchTabs')}`}>
          {<p className="tabsPara" style={{borderBottom: (currentDimensions > 1200 ? '3px solid white' : '3px solid black')}} >
              <span style={{ fontSize: currentDimensions < 600 && '15px' }}>{bookingName}</span>
          </p>}
      </div>
      <div className="feedbacksContainer" style={{ marginTop: (currentDimensions > 1200 ? '170px' : '110px') }}>
      {ratingsList?.length > 0 ? (
        <div>
          {ratingsList.map((item) => (
            <div key={item.id} className="feedbackDiv" style={{ border: `2px solid ${get_platform_bg_color()}` }}>
              <div className="quesLine" style={{ fontSize: (currentDimensions > 1200 ? '19px' : '14px') }}>{item.rating_ques}</div>
              <div className="rateDiv">
                <div style={{ display: "flex", gap: "5px" }}>
                  {[...Array(10).keys()].map((num) => {
                    const rating = num + 1;
                    const isSelected = userRatings[item.id]?.score === rating;
                    const isEditable = userRatings[item.id]?.disabled !== false;

                    return (
                      <div
                        key={rating}
                        onClick={() => {
                          if (isEditable) {
                            handleRatingChange(item.id, rating);
                          }
                        }}
                        className="raterNumber"
                        data-fdbkid={item.id}
                        style={{
                          border: (currentDimensions > 1200 ? `3px solid hsl(${(rating - 1) * 12}, 100%, 50%)` : `2px solid hsl(${(rating - 1) * 12}, 100%, 50%)`),
                          backgroundColor: isSelected ? `hsl(${(rating - 1) * 12}, 100%, 50%)` : "white",
                          cursor: isEditable ? "pointer" : "not-allowed",
                          opacity: isEditable ? 1 : 0.6,
                        }}
                      >
                        {rating}
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr className="hrDivision" style={{ border: `0.1rem solid ${get_platform_bg_color()}` }} />
              <div>
                <label>
                  {(() => {
                    const isEditable = userRatings[item.id]?.disabled !== false;
                    return (
                      <>
                        {isEditable ? (
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <textarea
                              style={{ display: 'flex' }}
                              value={userRatings[item.id]?.suggestion || ""}
                              onChange={(e) =>
                                handleFeedbackChange(item.id, e.target.value)
                              }
                              placeholder="Feedback (Optional)"
                              className="feedbackInput"
                            ></textarea>
                          </div>
                        ) : (
                            <div>
                              {userRatings[item.id]?.suggestion && (<p
                                style={{
                                  display: 'flex',
                                  marginTop: '10px',
                                  fontSize: currentDimensions > 1200 ? '17px' : '15px',
                                  textAlign: 'left',
                                  fontStyle: 'italic',
                                  color: 'gray',
                                }}
                              >
                                {userRatings[item.id]?.suggestion}
                              </p>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  fontSize: '13px',
                                  color: 'gray',
                                }}
                              >
                                {userRatings[item.id]?.user_name && <span>- {userRatings[item.id].user_name}</span>}
                              </div>
                            </div>
                        )}
                      </>
                    );
                  })()}
                </label>
              </div>
              <div
                className="submitBtnDiv"
                style={{
                  display: userRatings[item.id]?.disabled === false ? 'none' : 'flex',
                }}
              >
                <button
                  className="submitFdbkBtn"
                  onClick={() => handleSubmit(item.id)}
                >
                  Submit
                </button>
              </div>
              <div
                className="editBtnDiv"
                style={{
                  display: userRatings[item.id]?.editable && userRatings[item.id]?.disabled === false ? 'flex' : 'none',
                }}
              >
                <button
                  className="editFdbkBtn"
                  onClick={() => handleEdit(item.id)}
                >
                  Edit
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="noFeedbackText">This booking has no feedbacks yet.</p>
      )}
      </div>
      {showToast && <Toast message={toastMessage} duration={3000} onClose={() => setShowToast(false)}/>}
      </div>) :
      (<div className="overlayLoader">{globalLoader()}</div>)
      }
      
    </> 
  );
};

export default Ratings;
