import React, { useEffect, useRef, useState } from 'react'
import './Navbar.css'
import Sidebar from '../Sidebar/Sidebar'
import { token_url, get_logo, get_platform_bg_color, getAuthToken, getAPIUrl, get_platform_action_color, globalLoader, isAdmin } from '../../script/util'
import { useStateContext } from '../../context/StateContext'
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Popup from '../Popup/Popup';
import axios from 'axios'

const Navbar = ({ title, location }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [event, setEvent] = useState("Wedding");
  const [isLoading, setIsLoading] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(true);
  const upload = useRef(false)
  const { isOpen, setisOpen } = useStateContext();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  let left;
  if (location === token_url(`${process.env.REACT_APP_STAG_URL}`)) {
    left = <Link to={location}><img className='logoSize' src={get_logo(true)}/></Link>;
  } else {
    left = <Link to='/'><IoArrowBack /></Link>;
    upload.current = true
  }

  const WEDDING = "Wedding";
  const CORPORATE = "Corporate";
  const ENGAGEMENT_ROKA = "Engagement/Roka";
  const PRE_WEDDING = "Pre Wedding";
  const OTHER = "Other";

  useEffect(() => {
    initializeCallback();
  }, []);

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isPopupOpen]);

  const navigate = useNavigate();
  const handleBackPress = () => {
    navigate(`/?id=${id}`)
  };

  const profileName = () => {
    const nameInitial = JSON.parse(localStorage.getItem('auth_dict'))?.eventGraphiaInitials
    return nameInitial
  }

  const initializeCallback = () => {
    setSuccess(false);
    setErrorMessage("");
    setShowAdditionalDetails(true);
    setIsLoading(false);
  };

  const handleCallbackRequest = async (e) => {
    if(!isAdmin()){
      e.preventDefault();
      setShowAdditionalDetails(false);
      setIsLoading(true);

      if (!event) {
        setErrorMessage("Please enter event details");
        setShowAdditionalDetails(true);
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          getAPIUrl("/api/meeting/callback/"),
          { event },
          {
            headers: {
              "Authorization": getAuthToken(),
            },
          }
        );

        if (response.data.success) {
          setCallbackMessage(response.data.message);
          setSuccess(true);
        } else {
          setErrorMessage(response.data.message);
          setShowAdditionalDetails(false);
        }
      } catch (err) {
        setErrorMessage("Failed to connect to the server");
        setShowAdditionalDetails(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const closePopup = () => {
    setSuccess(false);
    setErrorMessage("");
    setShowAdditionalDetails(true);
    setEvent(WEDDING);
    setIsLoading(false);
    setPopupOpen(false);  
  }

  return (
    <>
      <div className="navbar">
        {!upload.current && <div className="nav-left" onClick={handleBackPress}>{left}</div>}
        {upload.current && <div className='nav-left' ><Link to='/'><img className='logoSize' src={get_logo(true)} /></Link></div>}
        {/* <div className="nav-middle">{title}</div> */}
        <div className="nav-right">
          <div className='connectDiv'>
            <p style={{ paddingRight: "20px", cursor: "pointer",}}>
              <a href={`https://www.instagram.com/${process.env.REACT_APP_PLATFORM}/`} style={{ display:"flex", alignItems:"center"  }} target='_blank'>
                <span className='brandIcon'>
                  <FontAwesomeIcon icon={faInstagram} size={window.innerWidth < 600 ? 'xl' : "sm"} style={{ color: "#000000", paddingRight:"5px"}} />
                </span>
                <span style={{ fontSize: window.innerWidth < 1200 && "15px" }} className='navBrandText'> {" "}Follow</span>
              </a>
            </p>
            <p 
              style={{ paddingRight: "20px", cursor: "pointer", display:"flex", alignItems:"center" }} 
              onClick={() => setPopupOpen(true)}
            >
              <span className='brandIcon'>
                <FontAwesomeIcon 
                  icon={faPhone} 
                  size={window.innerWidth < 600 ? 'xl' : "sm"} 
                  style={{ color: "#000000", paddingRight:"5px"}} 
                />
              </span>
              <span 
                className='navBrandText' 
                style={{ fontSize: window.innerWidth < 1200 && "15px" }}
              >
                {" "}Book Us
              </span>
            </p>
          </div>
          {(window.innerWidth>1200) && <div className='profileDiv' style={{ background: get_platform_bg_color(), border: `1px solid ${get_platform_bg_color()}` }} onClick={() => setisOpen(true)} >{profileName()}</div>}
        </div>
      </div>
      {
        isOpen && <Sidebar />
      }
      {isPopupOpen && (
        <Popup 
          onClose={() => closePopup()} 
          isOpen={isPopupOpen}
        >

        {
        isLoading && (<div id='acc-form'>
          {globalLoader()}
        </div>)
        }

        {
          showAdditionalDetails && !success && !errorMessage && (
            <div className="five pockets" id="acc-form"  style={{ paddingLeft: '2rem', paddingRight: '2rem' }}  >
            <h1 style={{ textAlign: "center", paddingTop: 10 }} className='accountDetailHead'>Which event do you want to book?
            </h1>
            <div className="form-el">
              <select id="event" value={event} onChange={(e) => setEvent(e.target.value)} >
                <option value="Wedding">{WEDDING}</option>
                <option value="Corporate">{CORPORATE}</option>
                <option value="Engagement/Roka">{ENGAGEMENT_ROKA}</option>
                <option value="Pre Wedding">{PRE_WEDDING}</option>
                <option value="Other">{OTHER}</option>
              </select>
            </div>
            <div className="form-el callback_btn">
              <button
                id="request-callback"
                className="book-prc-btn"
                style={{ backgroundColor: get_platform_action_color(), color: "#fff" }}
                onClick={handleCallbackRequest}
              >
                Request Callback
              </button>
            </div>
        </div>

          )
        }

        {
          success && !showAdditionalDetails && (
            <div id='acc-form' className='additional-details'>
              <div className="successbox">
                <div className='heading-line'></div>
                <h1 className='success'>SUCCESS!</h1>
                <div className='heading-line'></div>
              </div>
              <h2 style={{ textAlign: "center", paddingTop: 10 }} className='message'>
                {callbackMessage}
              </h2>
            </div>
          )
        }

        {
          errorMessage && !showAdditionalDetails && (
            <div id='acc-form'>
              <div className="successbox">
                <div className='heading-line error'></div>
                <h1 className='error'>ERROR!</h1>
                <div className='heading-line error'></div>
              </div>
              <h2 style={{ textAlign: "center", paddingTop: 10}}  className='message'>
                {errorMessage}
              </h2>
            </div>
          )
        }

        </Popup>
    ) }
    </>
  )
}

export default Navbar